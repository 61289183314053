import renameFunc from '@cvme/lib/helpers/renameFunc'
import { camelCase as _camelCase, get as _get } from 'lodash'

export const actionTypes = {
  LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS 🚀 ✅',

  PROFILE_REQUEST: 'PROFILE_REQUEST 🚀 💬',
  PROFILE_REQUEST_SUCCESS: 'PROFILE_REQUEST_SUCCESS 🚀 ✅',
  PROFILE_REQUEST_FAILED: 'PROFILE_REQUEST_FAILED 🚀 ❌',

  UPDATE_USER_REDUCER_DATA: 'UPDATE_USER_REDUCER_DATA 🚀 ✅',
}
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id)

  const creatorFunction = function _(payload) {
    return {
      type: _get(actionTypes, id),
      payload,
    }
  }

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName)
  return accum
}, {})
