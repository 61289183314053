import renameFunc from '@cvme/lib/helpers/renameFunc'
import { camelCase as _camelCase, get as _get } from 'lodash'

export const actionTypes = {
  CREATE_COMPANY_REQUEST: 'CREATE_COMPANY_REQUEST 🚀 💬',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS 🚀 ✅',
  CREATE_COMPANY_FAILED: 'CREATE_COMPANY_FAILED 🚀 ❌',

  ORGANIZATION_REQUEST: 'ORGANIZATION_REQUEST 🚀 💬',
  ORGANIZATION_REQUEST_SUCCESS: 'ORGANIZATION_REQUEST_SUCCESS 🚀 ✅',
  ORGANIZATION_REQUEST_FAILED: 'ORGANIZATION_REQUEST_FAILED 🚀 ❌',
}
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id)

  const creatorFunction = function _(payload) {
    return {
      type: _get(actionTypes, id),
      payload,
    }
  }

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName)
  return accum
}, {})
