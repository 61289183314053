import HandleAxiosError from 'library/helpers/HandleAxiosError'
import ApiClient from 'library/plugins/ApiClient'

export const createCompany = (payload) =>
  ApiClient.post('/organizations', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })

export const organization = (payload, handle) =>
  ApiClient.get('/organizations/${handle}', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })
