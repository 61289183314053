/* eslint-disable max-len */
import * as ReactIntl from 'react-intl'
import { get as _get, has as _has, toLower as _toLower } from 'lodash'

/**
 * @typedef {Object} i18nMessagesCurry
 * @property {function(...args): string} formatMessage
 * @property {function(...args): string} getFormatPlural
 */

/**
 * @typedef {Object} i18nMessages
 * @property {function(id: string, fallback: string): i18nMessagesCurry} get - return curry function to resolve `id` with opt. fallback
 * @property {function(id: string, ...args): string} getFormatMessage - return resolved i18n message for id
 * @property {function(id: string, ...args): string} getFormatPlural - return resolved i18n message for id
 */

const reactIntlWrapper = new (class ReactIntlWrapper {
  reactIntl = null

  constructor(ReactIntlAPI) {
    this.ReactIntlAPI = ReactIntlAPI
  }

  injectIntl = (reactIntlContext) => {
    this.reactIntl = reactIntlContext

    Object.entries(this.reactIntl).forEach(([key, val]) => {
      this[key] = typeof val === 'function' ? val.bind(this.reactIntl) : val
    })

    console.log('(i18n)[ReactIntlWrapper] inject-intl', {
      reactIntlContext: this.reactIntl,
    })

    return true
  }

  mute() {
    ;[
      'formatDate',
      'formatTime',
      'formatRelative',
      'formatNumber',
      'formatPlural',
      'formatMessage',
      'formatHTMLMessage',
    ].forEach((i) => {
      this[i] = () => {
        const func = this[i]

        try {
          return func()
        } catch (e) {
          console.error(e)
        }

        return undefined
      }
    })
  }

  /**
   * @example <caption> basic usage </caption>
   * import { defineMessages } from 'react-intl'
   *
   * reactIntlWrapper.i18nMessages(defineMessages({ ... }))
   *
   * @example <caption> with default message</caption>
   * import { defineMessages } from 'react-intl'
   *
   * reactIntlWrapper.i18nMessages(defineMessages({ ... }), 'all-ids-default-message')
   *
   *
   * @param {Object<string,Object>} defineMessages - object returned from `react-intl.defineMessages`
   * @param {string} defaultString - default message for any `id` not found and without fallback
   * @return {i18nMessages}
   */
  i18nMessages(defineMessages, defaultString) {
    const i18nStrings = defineMessages

    const formatKey =
      (fnKey, fallback) =>
      (key, ...args) => {
        if (_toLower(key) === 'canceled') key = 'cancelled' // eslint-disable-line

        const message = _get(i18nStrings, key, {})

        // debugger // eslint-disable-line
        // console.log('i18n :: ', { key, id: message.id, message, args }) // eslint-disable-line

        if (_has(message, 'id')) {
          return this[fnKey](message, ...args)
        }

        if (!process?.env.REACT_APP__PRD) {
          console.warn(
            '[i18n]',
            `key: ${key}\t fallback:${fallback}\t default:${defaultString}`
          )

          const fb = fallback ? `⊜${fallback}` : ''
          const df = defaultString && !fallback ? `⊛${defaultString}` : fb

          return `${df}`.length ? df : `⊘⣏${key}⣹`
        }

        return fallback || defaultString
      }

    const getFormatMessage = (fallback) => formatKey('formatMessage', fallback)
    const getFormatPlural = (fallback) => formatKey('formatPlural', fallback)

    i18nStrings.find = (key, fallback) => {
      if (!_has(defineMessages, key)) {
        return null
      }

      return i18nStrings.get(key, fallback)
    }

    i18nStrings.get = (key, fallback) => {
      return {
        formatMessage: (...args) => getFormatMessage(fallback)(key, ...args),
        formatPlural: (...args) => getFormatPlural(fallback)(key, ...args),
      }
    }

    i18nStrings.getFormatMessage = getFormatMessage()
    i18nStrings.getFormatPlural = getFormatPlural()

    return i18nStrings
  }

  defineMessages(messages) {
    const i18nStrings = this.ReactIntlAPI.defineMessages(messages)
    return this.i18nMessages(i18nStrings)
  }

  formatDate = () => {
    throw new Error('reactIntlAPI \t\t reactIntl was not injected')
  }

  formatTime = () => {
    throw new Error('reactIntlAPI \t\t reactIntl was not injected')
  }

  formatRelative = () => {
    throw new Error('reactIntlAPI \t\t reactIntl was not injected')
  }

  formatNumber = () => {
    throw new Error('reactIntlAPI \t\t reactIntl was not injected')
  }

  formatPlural = () => {
    throw new Error('reactIntlAPI \t\t reactIntl was not injected')
  }

  formatMessage = () => {
    throw new Error('reactIntlAPI \t\t reactIntl was not injected')
  }

  formatHTMLMessage = () => {
    throw new Error('reactIntlAPI \t\t reactIntl was not injected')
  }
})(ReactIntl)

export default reactIntlWrapper
