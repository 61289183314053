import { actionTypes } from './actions'

const initState = {
  createCompany: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
}

const organizationRequest = (state, payload) => ({
  ...state,
  isLoading: true,
})
const organizationRequestSucces = (state, payload) => ({
  ...state,
  organization: {
    ...payload,
  },
  isLoading: false,
  isSuccess: true,
  successMessage: payload.message,
  isFailed: false,
  error: null,
})
const organizationRequestFailed = (state, payload) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  successMessage: null,
  isFailed: true,
  error: payload,
})
// company
const createCompanyRequest = (state, payload) => ({
  ...state,
  createCompany: { isLoading: true },
})
const createCompanySucces = (state, payload) => ({
  ...state,
  createCompany: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    companyPanel: payload?.user_panel,
    companyHandle: payload?.handle,
    isFailed: false,
    error: null,
  },
})
const createCompanyFailed = (state, payload) => ({
  ...state,
  createCompany: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
})
export default function companyReducer(state = initState, { type, payload }) {
  switch (type) {
    // create company
    case actionTypes.CREATE_COMPANY_REQUEST:
      return createCompanyRequest(state, payload)
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return createCompanySucces(state, payload)
    case actionTypes.CREATE_COMPANY_FAILED:
      return createCompanyFailed(state, payload)
    case actionTypes.ORGANIZATION_REQUEST:
      return organizationRequest(state, payload)
    case actionTypes.ORGANIZATION_REQUEST_SUCCESS:
      return organizationRequestSucces(state, payload)
    case actionTypes.ORGANIZATION_REQUEST_FAILED:
      return organizationRequestFailed(state, payload)
    default:
      return state
  }
}
