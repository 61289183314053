import { lazy } from 'react'

export const PUBLIC_ROUTE = {
  LANDING: '/landing',
  SEARCH: '/jobs-search-results',
  JOBDETAILS: '/search/job-details/:id',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/set-password',
  VERIFY: '/verify',
  NEW_ACCOUNT: '/new-account',
}

export const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@cvme/containers/Landing')),
  },
  {
    path: PUBLIC_ROUTE.SEARCH,
    component: lazy(() => import('@cvme/containers/JobsSearchResults')),
    exact: false,
  },
  {
    path: '/search/job-details/:id',
    component: lazy(() =>
      import('@cvme/containers/JobsSearchResults/JobDetails')
    ),
    exact: true,
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    exact: true,
    component: lazy(() =>
      import('@cvme/containers/Authentication/SignUp/SignUp')
    ),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    exact: true,
    component: lazy(() => import('@cvme/containers/Authentication/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.NEW_ACCOUNT,
    exact: false,
    component: lazy(() =>
      import('@cvme/containers/Authentication/NewAccountMessage')
    ),
  },
  {
    path: PUBLIC_ROUTE.VERIFY,
    exact: false,
    component: lazy(() => import('@cvme/containers/Authentication/verify')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    exact: true,
    component: lazy(() =>
      import('@cvme/containers/Authentication/ForgetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    exact: true,
    component: lazy(() =>
      import('@cvme/containers/Authentication/SetPassword')
    ),
  },
]

export const privateRoutes = [
  {
    path: '/',
    component: lazy(() => import('@cvme/containers/Home')),
    exact: true,
  },
  {
    path: '/tag/:id',
    component: lazy(() => import('@cvme/containers/Tags')),
    exact: false,
  },
  {
    path: '/network',
    component: lazy(() => import('@cvme/containers/Network')),
    exact: false,
  },
  {
    path: '/jobs',
    component: lazy(() => import('@cvme/containers/Jobs')),
    exact: false,
  },
  {
    path: '/job/:id',
    component: lazy(() => import('@cvme/containers/Jobs/singleJob')),
    exact: false,
  },
  {
    path: '/settings',
    component: lazy(() => import('@cvme/containers/Settings')),
    exact: false,
  },
  {
    path: '/notifications',
    component: lazy(() => import('@cvme/containers/Notifications')),
    exact: false,
  },
  {
    path: '/create-company',
    component: lazy(() => import('@cvme/containers/Company/CreateCompany')),
    exact: false,
  },
  {
    path: '/company-page/:handle',
    component: lazy(() => import('@cvme/containers/Company/CompanyPage')),
    exact: false,
  },
  {
    path: '/create-job',
    component: lazy(() => import('@cvme/containers/Company/CreateJob')),
    exact: false,
  },
  {
    path: '/edit-job/:id',
    component: lazy(() => import('@cvme/containers/Company/EditJob')),
    exact: false,
  },
  {
    path: '/create-exam',
    component: lazy(() => import('@cvme/containers/Company/CreateExam')),
    exact: false,
  },
  {
    path: '/edit-exam/:id',
    component: lazy(() => import('@cvme/containers/Company/EditExam')),
    exact: false,
  },
  {
    path: '/company-jobs/job-details/:id',
    component: lazy(() => import('@cvme/containers/Company/JobDetails')),
    exact: false,
  },
  {
    path: '/company-posts/post-details/:id',
    component: lazy(() => import('@cvme/containers/Company/PostDetails')),
    exact: false,
  },
  {
    path: '/my-companies',
    component: lazy(() => import('@cvme/containers/Company/MyCompanies')),
    exact: false,
  },
  {
    path: '/company-jobs/:id/applications',
    component: lazy(() => import('@cvme/containers/Company/Applications')),
    exact: false,
  },
  {
    path: ['/inbox/:id', '/inbox'],
    component: lazy(() => import('@cvme/containers/Inbox')),
    exact: false,
  },
  {
    path: ['/profile'],
    component: lazy(() => import('@cvme/containers/Profile')),
    exact: false,
  },
  {
    path: '/user-profile/:id',
    component: lazy(() => import('@cvme/containers/UserProfile')),
    exact: false,
  },
]

export const completeRegisterRoute = {
  path: '/complete-register',
  exact: true,
  component: lazy(() =>
    import('@cvme/containers/Authentication/CompleteRegister')
  ),
}
export const NotFound = lazy(() =>
  import('@cvme/containers/Authentication/404/404')
)

export const generalRoutes = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('@cvme/containers/Landing')),
  },
  {
    path: '/terms',
    exact: true,
    component: lazy(() => import('@cvme/containers/TermsAndConditions/terms')),
  },
  {
    path: '/privacy',
    exact: true,
    component: lazy(() =>
      import('@cvme/containers/TermsAndConditions/privacy')
    ),
  },
  {
    path: '/404',
    component: lazy(() => import('@cvme/containers/Authentication/404/404')),
  },
  {
    path: '/500',
    component: lazy(() => import('@cvme/containers/Authentication/500/500')),
  },
  {
    path: '/faq',
    exact: true,
    component: lazy(() => import('@cvme/containers/Faq')),
  },
  {
    path: '/not-available',
    exact: true,
    component: lazy(() => import('@cvme/containers/NotAvailable')),
  },
]
