import HandleAxiosError from 'library/helpers/HandleAxiosError'
import ApiClient from 'library/plugins/ApiClient'

export const registerUser = (payload) =>
  ApiClient.post('/user/register', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })
export const saveCareerInfo = (payload) =>
  ApiClient.post('/user_experiences', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })

export const saveEducationInfo = (payload) =>
  ApiClient.post('/user_educations', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })

export const saveOrganizationInfo = (payload) =>
  ApiClient.post('/organizations', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })

export const verifyEmail = (payload) =>
  ApiClient.post('/user/email/verify', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })

export const loginUser = (payload) =>
  ApiClient.post('/user/login', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })

export const forgetPassword = (payload) =>
  ApiClient.post('/user/forget-password', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })
export const resetPassword = (payload) =>
  ApiClient.post('/user/password/reset', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })
