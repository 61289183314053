import { switchMap } from 'rxjs'
import { ofType } from 'redux-observable'
import { actions, actionTypes } from './actions'
import * as Api from './api'

console.log('🚀 ~ file: epics.js ~ line 9 ~ actions', actions)

const getUserProfileRequest = (action$) =>
  action$.pipe(
    ofType(actionTypes.PROFILE_REQUEST),
    switchMap((action) =>
      Api.userProfile(action.payload)
        .then((response) => actions.profileRequestSuccess(response))
        .catch((error) => actions.profileRequestFailed(error))
    )
  )
export default [getUserProfileRequest]
