import axios from 'axios'
import history from '@cvme/lib/helpers/history'
import { store, dispatch } from '@cvme/redux'
import { actionTypes } from 'redux/auth/actions'

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_BE_API,
  validateStatus: (status) => {
    if (status === 401 || status === 403) {
      // logout
      // history.push('/signin')
      dispatch({
        type: actionTypes.LOGOUT,
      })
      return false
    }
    if (status >= 200 && status <= 300) return true
    return false
  },
})

const token = localStorage.getItem('@cvme__token') || null
const user_panel = localStorage.getItem('@cvme_user_panel') || null

client.defaults.headers.common.Authorization = `Bearer ${token}`
client.defaults.headers.Language = 'en'
client.defaults.headers['User-Agent-Type'] = 'web'
client.defaults.headers['user-panel'] = user_panel
// client.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

export default client
