import { switchMap } from 'rxjs'
import { ofType } from 'redux-observable'
import { actions, actionTypes } from './actions'
import * as Api from './api'

// console.log('🚀 ~ file: epics.js ~ line 9 ~ actions', actions)
export const getOrganizationRequest = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORGANIZATION_REQUEST),
    switchMap((action) =>
      Api.organization(action.payload)
        .then((response) => actions.organizationRequestSuccess(response))
        .catch((error) => actions.organizationRequestFailed(error))
    )
  )
function createCompanyRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.CREATE_COMPANY_REQUEST),
    // eslint-disable-next-line arrow-body-style
    switchMap((action) => {
      // const FD = new FormData()
      // _forIn(action.payload, (value, key) => {
      //   FD.append(key, value)
      // })
      return Api.createCompany(action.payload)
        .then(actions.createCompanySuccess)
        .catch(actions.createCompanyFailed)
    })
  )
}

export default [createCompanyRequest]
