import { map, mapTo, mergeMap, of, concat, switchMap } from 'rxjs'
import { ofType } from 'redux-observable'
import { createBrowserHistory } from 'history'
import { PUBLIC_ROUTE } from 'route.constants'
import {
  values as _values,
  findIndex as _findIndex,
  forIn as _forIn,
} from 'lodash'
import { getToken, clearToken } from '@cvme/lib/helpers/utility'
import { store, dispatch } from '@cvme/redux'
import { actions, actionTypes } from './actions'
import { actions as userActions } from '../User/actions'
import * as Api from './api'

console.log('🚀 ~ file: epics.js ~ line 9 ~ actions', actions)

const history = createBrowserHistory()

function loginRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.LOGIN_REQUEST),
    switchMap((action) =>
      Api.loginUser(action.payload)
        .then((response) => actions.loginRequestSuccess(response))
        .catch((error) => {
          console.log(
            '🚀 ~ file: epics.js ~ line 23 ~ loginRequest ~ error',
            error
          )

          return actions.loginRequestFailed(error)
        })
    )
  )
}

// function loginSuccess(action$) {
//   return action$.pipe(
//     ofType(actionTypes.LOGIN_REQUEST_SUCCESS),
//     switchMap((action) => {
//       localStorage.setItem('@cvme__token', action.payload.token)
//     })
//   )
// }

function forgetPassword(action$) {
  return action$.pipe(
    ofType(actionTypes.FORGET_PASSWORD_REQUEST),
    switchMap((action) =>
      Api.forgetPassword(action.payload)
        .then((response) => actions.forgetPasswordSuccess(response))
        .catch((error) => actions.forgetPasswordFailed(error))
    )
  )
}
function resetPassword(action$) {
  return action$.pipe(
    ofType(actionTypes.RESET_PASSWORD_REQUEST),
    switchMap((action) =>
      Api.resetPassword(action.payload)
        .then((response) => actions.resetPasswordSuccess(response))
        .catch((error) => actions.resetPasswordFailed(error))
    )
  )
}

function registerRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.REGISTER_REQUEST),
    switchMap((action) =>
      Api.registerUser(action.payload)
        .then((data) =>
          {console.log(data);
            return actions.registerRequestSuccess({
            ...data,
            email: action.payload.email,
          })}
        )
        .catch((error) => actions.registerRequestFailed(error))
    )
  )
}

function registerCareerRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.REGISTER_CAREER_INFO_REQUEST),
    switchMap((action) =>
      Api.saveCareerInfo(action.payload)
        .then((response) => {
          dispatch(
            userActions.updateUserReducerData({
              career: true,
              careerFormData: action.payload,
            })
          )
          return actions.registerCareerInfoSuccess(response)
        })
        .catch(actions.registerCareerInfoFailed)
    )
  )
}

function registerEducationRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.REGISTER_EDUCATION_INFO_REQUEST),
    switchMap((action) =>
      Api.saveEducationInfo(action.payload)
        .then(actions.registerEducationInfoSuccess)
        .catch(actions.registerEducationInfoFailed)
    )
  )
}

function registerOrganizationRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.REGISTER_ORGANIZATION_INFO_REQUEST),
    // eslint-disable-next-line arrow-body-style
    switchMap((action) => {
      // const FD = new FormData()
      // _forIn(action.payload, (value, key) => {
      //   FD.append(key, value)
      // })
      return Api.saveOrganizationInfo(action.payload)
        .then(actions.registerOrganizationInfoSuccess)
        .catch(actions.registerOrganizationInfoFailed)
    })
  )
}

function verifyEmailRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.VERIFY_EMAIL_REQUEST),
    switchMap((action) =>
      Api.verifyEmail(action.payload)
        .then((response) => {
          if (response.user) {
            return actions.loginRequestSuccess(response)
          }
          return actions.verifyEmailRequestSuccess({})
          // of(
          //   actions.loginRequestSuccess(response),
          //   actions.verifyEmailRequestSuccess({})
          // )
        })
        .catch((error) => actions.verifyEmailRequestFailed(error))
    )
  )
}

// function RegisterRequestSuccess(action$) {
//   return action$.pipe(
//     ofType(actionTypes.REGISTER_REQUEST_SUCCESS),
//     mapTo(() => {
//       // redirect
//       // history.push('/verify', { email: action.payload.email })
//     })
//   )
// }

function logout(action$) {
  return action$.pipe(
    ofType(actionTypes.LOGOUT),
    switchMap(() => {
      const publicIndex = _findIndex(
        _values(PUBLIC_ROUTE),
        (x) => x === window.location.pathname
      )
      // call api
      clearToken()
      return of(
        actions.logoutSuccess({
          loading: false,
          shouldRedirect: publicIndex === -1,
        })
      )
    })
  )
}

// function checkAuthorization(action$) {
//   return action$.pipe(
//     ofType(actionTypes.CHECK_AUTHORIZATION),
//     mergeMap(() => {
//       const token = getToken().get('@cvme__token')
//       console.log(
//         '🚀 ~ file: epics.js ~ line 85 ~ mergeMap ~ token',
//         store.getState().User
//       )
//       if (token) {
//         return of(
//           actions.checkAuthorizationSuccess({
//             token,
//             profile: 'Profile',
//           })
//         )
//       }
//       console.log('else')
//       return of(actions.checkAuthorizationFailed({}))
//     })
//   )
// }

function checkPresist(action$) {
  return action$.pipe(
    ofType('persist/REHYDRATE'),
    switchMap((action) => {
      const { token } = action.payload ? action.payload.User : {}
      if (token) {
        return of(
          actions.checkAuthorizationSuccess({
            token: action.payload.User.token,
            profile: 'Profile',
          })
        )
      }
      // getToken().get('@cvme__token')
      return of(actions.logout())
    })
  )
}
export default [
  registerRequest,
  registerCareerRequest,
  registerEducationRequest,
  registerOrganizationRequest,
  verifyEmailRequest,
  // RegisterRequestSuccess,
  loginRequest,
  // loginSuccess,
  logout,
  // checkAuthorization,
  checkPresist,
  forgetPassword,
  resetPassword,
]
