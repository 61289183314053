import { isArray as _isArray } from 'lodash'

export default function HandleAxiosError(error) {
  try {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data)
      // console.log(error.response.status)
      // console.log(error.response.headers)

      // if (error.response.data.errors) {
      //   // if (response.code === 400) {
      //   //   window.location.href = `/new-account?email=${action.payload.email}`
      //   //   return Promise.resolve(() => ({ type: '' }))
      //   // }
      //   if (_isArray(error.response.data.errors)) {
      //     // const errorsArr = Object.values(error?.response?.data?.errors)
      //     if (error.response.data.errors[0].code === '1512') {
      //       window.location.href = `/new-account?email=${error.response.data.errors[0].email}`
      //       return error.response.data.errors[0].error
      //     }
      //     return error.response.data.errors[0]?.message
      //   }
      //   if (error.response.data.errors.error){
      //     return error.response.data.errors.error
      //   }

      //   return error.response.data.message
      // }
      if (error.response.data.errors) {
        const errorsArr = Object.values(error?.response?.data?.errors)
        return errorsArr[0][0]
      }
      if (error.response.data.error) {
        if (error.response.data.error.code === '1512') {
          window.location.href = `/new-account?email=${error.response.data.error.email}`
          return error.response.data.error.error
        }
        return (
          error.response.data.error.error || 'API Response structure Invalid'
        )
      }
      // return error.response.data.message
      return 'API Response structure Invalid'
    }
    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return error.request
    }
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message)
    return error.message
  } catch (err) {
    return 'unexpected error has been catched'
  }
}
