import { combineReducers } from 'redux'
import App from '@cvme/redux/app/reducer'
import Auth from '@cvme/redux/auth/reducer'
import User from '@cvme/redux/User/reducer'
import Company from '@cvme/redux/company/reducer'
import ThemeSwitcher from '@cvme/redux/themeSwitcher/reducer'
import LanguageSwitcher from '@cvme/redux/languageSwitcher/reducer'
import Profile from '@cvme/redux/profile/reducer'

const allReducers = combineReducers({
  Auth,
  User,
  Company,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Profile,
})

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }

  return allReducers(state, action)
}
export default rootReducer
