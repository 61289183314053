import React, { Suspense } from 'react'
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
import { get as _get } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { reactIntl } from '@cvme/lib/helpers/i18n'
import Loader from '@cvme/components/utility/loader'
import {
  publicRoutes,
  privateRoutes,
  completeRegisterRoute,
  generalRoutes,
  NotFound,
} from './route.constants'
import ErrorBoundary from './ErrorBoundary'

function PrivateRoute({ children, ...rest }) {
  const User = useSelector((state) => state.User || {}, shallowEqual)
  const isLoggedIn = _get(User, 'token', false)
  const isProfileComplete = _get(User, 'user.educations', false)
  // const isProfileComplete =
  //   _get(User, 'user.user_register') === 'user'
  //     ? _get(User, 'user.education', false)
  //     : _get(User, 'user.organization', false)

  if (isLoggedIn && isProfileComplete) {
    return <Route {...rest}>{children}</Route>
  }
  if (!isLoggedIn && !isProfileComplete) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )}
      />
    )
  }
  if (isLoggedIn && !isProfileComplete) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/complete-register',
              state: { from: location },
            }}
          />
        )}
      />
    )
  }
  return null
}

function PublicRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.User.token)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

function Routes(props) {
  reactIntl.injectIntl(props.intl)

  const User = useSelector((state) => state.User || {}, shallowEqual)
  const isLoggedIn = _get(User, 'token', false)
  const isProfileComplete = _get(User, 'user.educations', false)
  // const isProfileComplete =
  //   _get(User, 'user.user_register') === 'user'
  //     ? _get(User, 'user.education', false)
  //     : _get(User, 'user.organization', false)
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route) => (
              <PublicRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
              >
                <route.component />
              </PublicRoute>
            ))}
            {privateRoutes.map((route) => (
              <PrivateRoute
                exact={route.exact}
                key={route.path}
                path={route.path}
              >
                <route.component />
              </PrivateRoute>
            ))}
            <Route
              exact={completeRegisterRoute.exact}
              path={completeRegisterRoute.path}
              render={({ location }) =>
                isLoggedIn && !isProfileComplete ? (
                  <completeRegisterRoute.component />
                ) : (
                  <Redirect
                    to={{
                      pathname: '/signin',
                      state: { from: location },
                    }}
                  />
                )
              }
            />
            {generalRoutes.map((route) => (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}
export default injectIntl(Routes)
