import React from 'react'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import GlobalStyles from 'assets/styles/globalStyle'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor, queryClient } from './redux'
// import Boot from './redux/boot'
import Routes from './router'
import AppProvider from './AppProvider'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppProvider>
            <GlobalStyles />
            <Routes />
          </AppProvider>
        </PersistGate>
      </Provider>
      <ToastContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
// Boot()
//   .then(() => App())
//   .catch((error) => console.error(error))

export default App
