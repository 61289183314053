import history from '@cvme/lib/helpers/history'

import { actionTypes } from './actions'

const initState = {
  idToken: null,
  register: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  verify: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  completeRegister: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  completeRegisterEducation: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  completeRegisterOrganization: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  login: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
}
// register
const registerRequest = (state, payload) => ({
  ...state,
  register: { isLoading: true },
})
const registerRequestSucces = (state, payload) => ({
  ...state,
  register: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
})
const registerRequestFailed = (state, payload) => ({
  ...state,
  register: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
})
// career info
const registerCareerInfoRequest = (state, payload) => ({
  ...state,
  completeRegister: { isLoading: true },
})
const registerCareerInfoRequestSucces = (state, payload) => ({
  ...state,
  completeRegister: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
})
const registerCareerInfoRequestFailed = (state, payload) => ({
  ...state,
  completeRegister: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
})
// education
const registerEducationRequest = (state, payload) => ({
  ...state,
  completeRegisterEducation: { isLoading: true },
})
const registerEducationRequestSucces = (state, payload) => ({
  ...state,
  completeRegisterEducation: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
})
const registerEducationRequestFailed = (state, payload) => ({
  ...state,
  completeRegisterEducation: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
})
// organization
const registerOrganizationRequest = (state, payload) => ({
  ...state,
  completeRegisterOrganization: { isLoading: true },
})
const registerOrganizationSucces = (state, payload) => ({
  ...state,
  completeRegisterOrganization: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
})
const registerOrganizationFailed = (state, payload) => ({
  ...state,
  completeRegisterOrganization: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
})
// verify
const verifyEmailRequest = (state, payload) => ({
  ...state,
  verify: { isLoading: true },
})
const verifyEmailRequestSuccess = (state, payload) => ({
  ...state,
  verify: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
})
const verifyEmailRequestFailed = (state, payload) => ({
  ...state,
  verify: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
})
// login
const loginRequest = (state, payload) => ({
  ...state,
  login: { isLoading: true },
})
const loginRequestSucces = (state, payload) => {
  localStorage.setItem('@cvme__token', payload.token)
  window.location.reload()
  return {
    ...state,
    login: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
  }
}
const loginRequestFailed = (state, payload) => ({
  ...state,
  login: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
})
// forget pass
const forgetPasswordRequest = (state, payload) => ({
  ...state,
  forgetPassword: { isLoading: true },
})
const forgetPasswordSucces = (state, payload) => ({
  ...state,
  forgetPassword: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
})
const forgetPasswordFailed = (state, payload) => ({
  ...state,
  forgetPassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
})
// reset pass
const resetPasswordRequest = (state, payload) => ({
  ...state,
  resetPassword: { isLoading: true },
})
const resetPasswordSucces = (state, payload) => ({
  ...state,
  resetPassword: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
})
const resetPasswordFailed = (state, payload) => ({
  ...state,
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
})

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    // register
    case actionTypes.REGISTER_REQUEST:
      return registerRequest(state, payload)
    case actionTypes.REGISTER_REQUEST_SUCCESS:
      return registerRequestSucces(state, payload)
    case actionTypes.REGISTER_REQUEST_FAILED:
      return registerRequestFailed(state, payload)
    // career info
    case actionTypes.REGISTER_CAREER_INFO_REQUEST:
      return registerCareerInfoRequest(state, payload)
    case actionTypes.REGISTER_CAREER_INFO_SUCCESS:
      return registerCareerInfoRequestSucces(state, payload)
    case actionTypes.REGISTER_CAREER_INFO_FAILED:
      return registerCareerInfoRequestFailed(state, payload)
    // education info
    case actionTypes.REGISTER_EDUCATION_INFO_REQUEST:
      return registerEducationRequest(state, payload)
    case actionTypes.REGISTER_EDUCATION_INFO_SUCCESS:
      return registerEducationRequestSucces(state, payload)
    case actionTypes.REGISTER_EDUCATION_INFO_FAILED:
      return registerEducationRequestFailed(state, payload)
    // organization
    case actionTypes.REGISTER_ORGANIZATION_INFO_REQUEST:
      return registerOrganizationRequest(state, payload)
    case actionTypes.REGISTER_ORGANIZATION_INFO_SUCCESS:
      return registerOrganizationSucces(state, payload)
    case actionTypes.REGISTER_ORGANIZATION_INFO_FAILED:
      return registerOrganizationFailed(state, payload)
    // verify
    case actionTypes.VERIFY_EMAIL_REQUEST:
      return verifyEmailRequest(state, payload)
    case actionTypes.VERIFY_EMAIL_REQUEST_SUCCESS:
      return verifyEmailRequestSuccess(state, payload)
    case actionTypes.VERIFY_EMAIL_REQUEST_FAILED:
      return verifyEmailRequestFailed(state, payload)
    // login
    case actionTypes.LOGIN_REQUEST:
      return loginRequest(state, payload)
    case actionTypes.LOGIN_REQUEST_SUCCESS:
      return loginRequestSucces(state, payload)
    case actionTypes.LOGIN_REQUEST_FAILED:
      return loginRequestFailed(state, payload)
    // forget
    case actionTypes.FORGET_PASSWORD_REQUEST:
      return forgetPasswordRequest(state, payload)
    case actionTypes.FORGET_PASSWORD_SUCCESS:
      return forgetPasswordSucces(state, payload)
    case actionTypes.FORGET_PASSWORD_FAILED:
      return forgetPasswordFailed(state, payload)
    // reset
    case actionTypes.RESET_PASSWORD_REQUEST:
      return resetPasswordRequest(state, payload)
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSucces(state, payload)
    case actionTypes.RESET_PASSWORD_FAILED:
      return resetPasswordFailed(state, payload)
    // case actionTypes.LOGIN_REQUEST:
    //   return loginRequest(state, payload)
    // case actionTypes.LOGIN_REQUEST_SUCCESS:
    //   return {
    //     idToken: payload.token,
    //   }
    case actionTypes.LOGOUT_SUCCESS:
      if (payload.shouldRedirect) {
        localStorage.removeItem('@cvme_user_panel')
        localStorage.removeItem('organization-handle')
        window.location.replace('/landing')
      }
      return initState
    case actionTypes.FLUSH_AUTH_REDUCER:
      return initState
    default:
      return state
  }
}
